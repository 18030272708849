<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="12">
                        <CButton
                            @click="$router.go(-1)"
                            size="sm"
                            color="warning" 
                        > 
                            <font-awesome-icon icon="arrow-left"/> Go back
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="8">
                        <CCard>
                            <CCardHeader>
                                <font-awesome-icon icon="cog"/>  Manage Category
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>

                                <template v-if="document_template_category.status == 'Active'">
                                    <CButton 
                                        size="sm"
                                        color="danger" 
                                        class="float-right" 
                                        @click="toggleEdit(), returnInformation()"
                                        v-show="edit"
                                    >
                                        <font-awesome-icon icon="window-close"/> Cancel
                                    </CButton>
                                    <CButton 
                                        v-if="$store.getters['can']('update-document-template-category')"
                                        size="sm"
                                        :disabled="!isValid"
                                        color="success" 
                                        class="float-right" 
                                        v-show="edit" 
                                        @click="updateDocumentTemplateCategory()"
                                    >
                                        <font-awesome-icon icon="save"/> Update
                                    </CButton>
                                    <CButton 
                                        v-if="$store.getters['can']('edit-document-template-category')"
                                        size="sm"
                                        color="info" 
                                        class="float-right" 
                                        @click="toggleEdit" v-show="!edit"
                                    >
                                        <font-awesome-icon icon="edit"/> Edit
                                    </CButton>
                                </template>
                                <template v-if="document_template_category.status == 'Archived'">
                                    <CButton 
                                        v-if="$store.getters['can']('restore-document-template-category')"
                                        size="sm"
                                        color="success" 
                                        class="float-right" 
                                        @click="restore()"
                                    >
                                        <font-awesome-icon icon="trash-restore"/> Restore
                                    </CButton>
                                </template>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="12">
                                        <h5>Status: <CBadge :color="getColorStatus(document_template_category.status)">{{document_template_category.status}}</CBadge></h5>
                                    </CCol>
                                </CRow>
                                <CRow> 
                                    <CCol lg="6" md="6" xl="6"> 
                                        <label for="favcolor">Color</label> <label>{{ color }}</label>
                                            <CInput 
                                                :disabled="disable == 1? true : false"
                                                :lazy="false"
                                                :value.sync="$v.document_template_category.color.$model"
                                                :isValid="checkIfValid('color')"
                                                type="color" 
                                                value="#ff0000"
                                                v-model="document_template_category.color"  
                                            />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="6" md="6" xl="6">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :value.sync="$v.document_template_category.category.$model"
                                            :isValid="checkIfValid('category')"
                                            label="Name" 
                                            type="text" 
                                            autocomplete="off" 
                                            v-model="document_template_category.category"                                                                                                                   
                                            invalidFeedback="Must be three(3) characters." 
                                            v-tocapitalize
                                            v-nospecialcharacter                              
                                        />
                                    </CCol>
                                </CRow> 
                                <br v-show="edit">
                                
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CRow v-show="edit">
                    <CCol lg="8">
                        <CCard borderColor="danger" v-if="$store.getters['can']('archive-document-template-category')">
                            <CCardHeader>
                                <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/> 
                                        Danger Zone
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="8">
                                        <h6>Archive this Category ?</h6>
                                    </CCol>
                                    <CCol lg="12">
                                        <label> Once archived, you cannot use this category anymore. Please be certain.</label>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="3">
                                        <CButton shape="pill" color="warning" @click="archive()" > 
                                            <font-awesome-icon icon="minus-square" /> Archived 
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        </div>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateCategoryEdit',
    data() {
        return {    
            category: null,
            color: null,
            disable: 1,
            edit: false,

            document_template_category: { 
                id: this.$route.params.id, 
                category: null,
                color: null,
                status: null,
            },
            document_template_category_backup: {
                id: this.$route.params.id,
                category: null,
                color: null,
                status: null,
            },
        }
    },
    computed: {
        isValid () { return !this.$v.document_template_category.$invalid },
        isDirty () { return this.$v.document_template_category.$anyDirty },
    },
    validations: {
        document_template_category: {
            category: { required, minLength: minLength(3)  },
            color: { required, },
        }
    },
    created() {
        this.document_template_category.id = this.$route.params.id  
        this.$Progress.start()
        this.getDocumentTemplateCategory();
        this.$Progress.finish()
    },
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.document_template_category[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            this.document_template_category = Vue.util.extend({}, this.document_template_category_backup)
        },
        getDocumentTemplateCategory: function() { //(list)
            axios.get('drs/document-template-category/' + this.document_template_category.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data)
                }
            })
        },
        assignData: function(response){
            this.document_template_category = response.data;
            this.document_template_category.status = response.data.deleted_at ? 'Archived' : 'Active';
            this.document_template_category_backup = Vue.util.extend({}, response.data);
        },
        validateDocumentTemplateCategory:function () {
            if( !this.document_template_category.category || !this.document_template_category.color) {
                return false;
            } 
            return true;
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.document_template_category.category}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('drs/document-template-category/archive/' + this.document_template_category.id, {validateStatus: () => true})
                        .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.document_template_category.category} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.document_template_category.status = 'Archived'
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('drs/document-template-category/restore/' + this.document_template_category.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.document_template_category.category} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.document_template_category = Vue.util.extend({}, this.document_template_category_backup)
                    this.document_template_category.status = 'Active'
                    this.$Progress.finish() 
                }
            })
        },
        updateDocumentTemplateCategory: function () {
            if(JSON.stringify(this.document_template_category_backup) == JSON.stringify(this.document_template_category)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(this.validateDocumentTemplateCategory()) {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the category.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        let data= {
                            category: this.document_template_category.category,
                            color: this.document_template_category.color,
                        }
                        return axios.post('drs/document-template-category/update/' + this.document_template_category.id, data, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.document_template_category.category} has been updated.`,
                                }).then(() => {
                                    this.assignData(response.data)
                                    this.toggleEdit()
                                    this.$v.$reset()
                                    this.$v.$touch()
                                    this.$Progress.finish()
                                })
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
            return;
        },
        update: function () {
            this.$Progress.start()
            let data= {
                category: this.document_template_category.category,
                color: this.document_template_category.color,
            }
            axios.post('drs/document-template-category/update/' + this.document_template_category.id, data, {validateStatus: () => true})
            .then(response => {
                if( response.status == 200 ) {
                    this.$swal({
                        icon: "success",
                        title: "Success!",
                        text: `${this.document_template_category.category} has been updated.`,
                    }).then(() => {
                        this.assignData(response.data)
                        this.toggleEdit()
                        this.$Progress.finish()
                    })
                }
            })
        }
    },
} 
</script>
